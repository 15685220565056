import { defineStore } from 'pinia'

export const useUserStore = defineStore({
  id: 'user',
  state: () => ({
		is_loggedin: false,
		user: {},
		session_check: -1
  }),
  getters: {
  }, 
  actions: {
  }
})
