import { storeToRefs } from "pinia";
import { useUserStore } from "@/stores/user";
import Api from "@/services/Api";

export default async function auth() {
  const user = storeToRefs(useUserStore());

  user.session_check.value = -1;
  await Api(true)
    .post("session/check")
    .then((r) => {
      if (r.data == 1) {
        user.session_check.value = 1;
        user.is_loggedin.value = true;
        user.user.value = JSON.parse(localStorage.user);
      }
    })
    .catch(() => {
      user.session_check.value = 0;
      user.is_loggedin.value = true;
    });
  if (!user.is_loggedin.value && user.session_check.value != -1) {
    return {
      name: "login",
    };
  } else if (user.is_loggedin.value) {
    console.log("user is logged in", user.session_check.value);
    if (user.user.value.is_active == 0) {
      user.session_check.value = 1;
      return {
        name: "awaiting-verification",
      };
    }
  }
}
