<template>
  <div>
    <Navbar></Navbar>
    <div class="mt-6 mb-6 p-2">
      <router-view></router-view>
      <modals-container></modals-container>
    </div>
  </div>
</template>
<script>
import { storeToRefs } from "pinia";
import { useUserStore } from "@/stores/user";
import Api from "./services/Api";
import { defineAsyncComponent } from "vue";
import { ModalsContainer } from "vue-final-modal";

export default {
  data() {
    return {
      user_store: storeToRefs(useUserStore()),
    };
  },
  name: "App",
  components: {
    Navbar: defineAsyncComponent(() => import("./layout/NavBar.vue")),
    ModalsContainer,
  },
  methods: {
    clearLocalStorage() {
      localStorage.removeItem("user");
      localStorage.removeItem("token");
    },
    gotoLogin() {
      this.user_store.session_check = 1;
      this.user_store.is_loggedin = false;
      this.$router.push({ path: "/login" });
    },
  },
  created() {
    if (!localStorage.token) {
      this.user_store.session_check = 1;
      this.user_store.is_loggedin = false;
      return;
    }
    this.user_store.session_check = -1;
    Api(true)
      .post("session/check")
      .then((r) => {
        if (r.data == 1) {
          this.user_store.session_check = 1;

          if (this.$route.path == "/login" || this.$route.path == "/register")
            this.$router.push({ path: "/home" });
          this.user_store.is_loggedin = true;
          this.user_store.user = JSON.parse(localStorage.user);
        }
      })
      .catch(() => {
        this.gotoLogin();

        this.clearLocalStorage();
      });
  } /*
    else {
      if (this.$route.path != "/login") this.gotoLogin();
      else {
        this.user_store.session_check = 1;
        this.user_store.is_loggedin = false;
      }
      this.clearLocalStorage();
    }*/,
  // },
};
</script>

<style>
* {
  font-family: "Dosis", sans-serif;
}
.button,
a {
  font-family: "Prompt", sans-serif;
}
.border-top {
  border-width: 1px;
  border-top-style: solid;
  padding-top: 20px;
  border-color: #f5f5f5;
}
.is-grid {
  display: grid;
}
.group-report {
  text-align: center !important;
  vertical-align: middle !important;
}
.margin-auto {
  margin: auto;
}
.clearfix {
  clear: both;
  overflow: auto;
}
</style>
