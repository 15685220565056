import { createRouter, createWebHistory } from 'vue-router'
import auth from '@/middleware/auth'

const routes = [
  { path: '/', component: () => import('./pages/LandingPage.vue') },
  { path: '/help', component: () => import('./pages/Help.vue') },
  {
    name: 'login', path: '/login',
    component: () => import('./pages/Login.vue')
  },
  {
    name: 'register', path: '/register',
    component: () => import('./pages/Register.vue')
  },
  {
    name: 'home', path: '/home',
    component: () => import('./pages/Home.vue'),
    beforeEnter: () => auth(),
  },
  {
    name: 'contracts', path: '/contracts',
    component: () => import('./pages/Contracts.vue'),
    beforeEnter: () => auth(),
  },
  {
    name: 'customers', path: '/customers',
    component: () => import('./pages/customers/Customers.vue'),
    beforeEnter: () => auth(),
  },
  {
    name: 'scedule', path: '/scedule',
    component: () => import('./pages/Scedule.vue'),
    beforeEnter: () => auth(),
  },
  {
    path: '/verify/:token',
    component: () => import('./pages/user/verify.vue')
  },
  {
    name: 'awaiting-verification',
    path: '/awaiting-verification',
    component: () => import('./pages/user/waiting_confirmation.vue'),
  },
]


const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
