import axios from 'axios'

export default (token) => {
  var auth = "none"
  if (token) auth = "Bearer " + localStorage.token
  return axios.create({
    baseURL: process.env.NODE_ENV == 'production'
      ? 'https://cafm.api.feps.com/api/'
      : 'http://localhost:8000/api/',

    headers: {
      Authorization: auth
    }
  })
}
