import { createApp } from "vue";
import { createPinia } from "pinia";
import App from "./App.vue";
import router from "./routes";
import Toaster from "@incuca/vue3-toaster";
import VueNumberFormat from "vue-number-format";
import "./index.css";
// import Modal from "vue3-modal";
import { vfmPlugin } from "vue-final-modal";
const pinia = createPinia();
const app = createApp(App);
app.use(VueNumberFormat, {
  prefix: "",
  decimal: ".",
  thousand: ",",
});
app.use(vfmPlugin);

app.use(router);
app.use(Toaster);
app.use(pinia);
app.config.globalProperties.$document = document;

app.mount("#app");
